@import "../config";

.pandaProject {
  margin: $spacing-s 0;

  &__image {
    overflow: hidden;

    .pandaImage {
      transition: 0.5s ease;
    }
  }

  &__text {
    padding: $spacing-s $spacing-m;

    @include breakpoint(medium) {
      padding-left: 0;
      padding-right: 0;
    }

    &__category {
      color: $color-text-faded;
      font-size: $font-size-xs;
      margin-bottom: $spacing-xxs;
    }

    &__title {
      font-size: rem(20);
      position: relative;
      color: $color-text;
    }

    @media (min-width: 1024px) and (max-width: 1199px) {
      &__category {
        font-size: $font-size-xxs;
        margin-bottom: $spacing-xxs / 2;
      }

      &__title {
        font-size: rem(18);
      }
    }
  }

  &:hover {
    .pandaProject__image .pandaImage {
      transform: scale(1.05);
    }
  }

  &__outline {
    font-size: $font-size-s;
  }

  .main--inverted section:not(.related-cases) & {
    .pandaProject__text__category {
      color: $color-white;
      opacity: 0.7;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .inverted & {

    /* stylelint-disable-next-line no-descending-specificity */
    .pandaProject__text__category {
      color: $color-white;
      opacity: 0.7;
    }

    .pandaProject__text__title {
      color: $color-white;
    }
  }
}

.toggle-customer {
  &::before {
    content: '+';
    display: inline-block;
    padding-right: rem(8);
    width: rem(20);
    text-align: center;
  }

  &.is-active {
    &::before {
      content: '–';
    }
  }
}

/* purgecss start ignore */
.pandaProjectList {
  .pandaProjectList__entry {
    width: 100%;
  }

  @include breakpoint(large) {
    padding: 0;
    margin-bottom: $spacing-xl;

    &__masonry-sizer {
      width: 100% * 1/3;
    }

    .pandaProjectList__entry {
      width: 100% * 1/3;
    }

    .pandaProjectList__entry--with-prio {
      width: 66.6666%;
    }

    .case-detail & {
      padding: 0;
    }
  }
}

@include breakpoint(medium) {
  .pandaRelatedCases {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .pandaProject {
      width: 50%;

      &--full {
        width: 100%;
      }
    }
  }
}

/* purgecss end ignore */

.back-to-overview {
  display: none;

  @include breakpoint(medium) {
    display: block;
    position: absolute;
    left: rem-calc(30);
    top: rem-calc(40);
    z-index: 1;
  }

  @include breakpoint(large) {
    left: rem-calc(40);
  }
}

@include breakpoint(medium) {
  .wrapped.outside {
    width: calc(100% - 105px);
  }
}

main.main--inverted .back-to-overview {
  .pandaArrow__wrapper {
    &::after {
      border-color: $color-white;
    }

    &::before {
      background-color: $color-white;
    }
  }
}

.related-cases {
  margin-top: $spacing-xl;

  @include breakpoint(medium) {
    margin-top: $spacing-xxxl;
  }

  div {
    color: $color-text;

    &.pandaProject__text__category {
      color: $color-text-faded;
    }
  }
}

.case-detail {
  h1 {
    margin-bottom: 0;
  }
}
