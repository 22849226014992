@import "../config";

.pandaCustomerList {
  height: auto;
  overflow: hidden;
  display: none;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 $spacing-l 0;

    li {
      font-family: $font-decor;
      font-size: $font-size-title-s;
      padding: rem-calc(3) 0;

      @include breakpoint(small only) {
        flex-basis: 100%;
      }

      @include breakpoint(medium) {
        flex-basis: 50%;
      }

      @include breakpoint(large) {
        flex-basis: 33.3%;
      }

      > a {
        text-decoration: underline;
      }
    }
  }
}
