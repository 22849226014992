@import "../config";

/**
 * Add a slight background color to scale-containers
 */

.scale-container {
  .element-scaler {
    background-color: rgba($color-black, 0);
  }
}
