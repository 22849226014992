/**
 * components/global.css
 *
 * Project-wide styles
 *
 */
@import "../config";
@import "mixins";

/* purgecss start ignore */
html {
  font-size: $font-size-m;
  box-sizing: border-box;
  text-size-adjust: 100%;
  height: 100%;
  @include breakpoint(medium) {
    font-size: $font-size-l;
  }
  @include breakpoint(xxlarge) {
    font-size: $font-size-xl;
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html.lock {
  overflow: hidden;
  margin: 0;
  max-height: 100%;
}

@include breakpoint(small only) {
  html.menu-open {
    overflow: hidden;

    body {
      overflow: hidden;
      height: 0;
    }
  }
}

body {
  margin: 0 auto;
  line-height: $line-height-normal;
  font-synthesis: none;
  font-family: $font-primary;
  color: $color-text;
  background-color: $color-white;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga', 'kern';

  .lock & {
    overflow: hidden;
    height: 0;
  }

  > * {
    transition: opacity .3s;
  }

  &.init {
    background: $color-white !important;

    > * {
      visibility: hidden;
    }

    #pandaIntro {
      visibility: visible;
    }
  }

  @include breakpoint(medium) {
    border: 20px solid $color-white;
  }

  main {
    position: relative;

    &.alternate-bg {
      background: $color-ultra-light;
      @include breakpoint(medium) {
        padding: $spacing-xl $spacing-m;
      }
    }
  }

  &.background {
    &--y {
      background: $color-yellow;

      header,
      main {
        background: $color-yellow;
      }
    }

    &--be {
      background: $color-beige;

      header,
      main {
        background: $color-beige;
      }
    }

    &--ly {
      background: $color-light-yellow;

      header,
      main {
        background: $color-light-yellow;
      }
    }

    &--g {
      background: $color-green;

      header,
      main {
        background: $color-green;
      }
    }

    &--404 {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      header {
        background: none;
        z-index: $zindex-level-2;
        color: $color-white;
      }

      .animation {
        background: url('../../img/404.gif') no-repeat;
        background-size: cover;
        background-position: center center;
        color: $color-white;
        z-index: $zindex-level-1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint(medium) {
          top: 20px;
          bottom: 20px;
          left: 20px;
          right: 20px;
        }
      }

      footer {
        padding-top: $spacing-xl;
      }

      main {
        position: unset;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        footer {
          position: relative;
          z-index: $zindex-level-2;
        }
      }
    }

    &--b {
      background: $color-black;

      header,
      main {
        background: $color-black;
        color: $color-white;
      }
    }
  }
}

a,
button,
label {
  @include remove-tap-highlight();

  outline: 0;
}

section,
div {
  text-align: left;

  &.wrapped {
    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }

  &.divider {
    @include divider($spacing-xxl);
  }

  &.stage {
    background: $color-ultra-light;

    &.media {
      + .stage.media {
        margin-top: 0;
      }
    }

    + section:not(.stage) {
      margin-top: $spacing-xl;

      @include breakpoint(medium) {
        margin-top: $spacing-xxxl;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:not(.stage) {
    + section.stage,
    + section.full-width {
      margin-top: $spacing-xl;

      @include breakpoint(medium) {
        margin-top: $spacing-xxxl;
      }
    }
  }

  &.full-width + section.stage,
  &.full-width + section.full-width,
  &.stage + section.full-width {
    margin-top: 0;
  }

  &.horizontal-scroll {
    -webkit-overflow-scrolling: touch;
  }

  &.media + section.quote {
    padding-top: 0;
  }

  @include breakpoint(small only) {
    &.wrapped {
      padding-left: 0;
      padding-right: 0;

      &.inside,
      &.padding-on-small {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }
    }

    &.horizontal-scroll {
      padding-left: 0;
      padding-right: 0;

      .grid-margin-x {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.alternate {
      background: $color-light-gray;
      padding-top: $spacing-xl;
      padding-bottom: $spacing-xl;
    }

    &.divider {
      padding-bottom: 0;
    }
  }

  @include breakpoint(medium) {
    &.inside {
      width: calc(100% - 40px);

      .wrapped.outside & {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.inside-till-medium {
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
      width: $el-width-menu-safe;
    }
  }

  @include breakpoint(large) {
    &.inside-till-medium {
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
      width: 100%;
    }

    &.wrapped {
      &.outside {
        width: $el-width-menu-safe;

        + .outside {
          margin-top: $spacing-xl;
        }
      }
    }

    &.wrapped,
    &.wrapped-medium {
      margin-left: auto;
      margin-right: auto;
      max-width: $content-width;
      padding-left: $spacing-m;
      padding-right: $spacing-m;
    }

    &.outside {
      max-width: $content-width-outside;
      margin-left: auto;
      margin-right: auto;
    }

    &.inside {
      max-width: 66.66%;
      margin-left: auto;
      margin-right: auto;

      .wrapped.outside & {
        width: rem(960);
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include breakpoint(large) {
    &.inside {
      max-width: 80%;

      .wrapped.outside & {
        max-width: 82%;
      }
    }
  }
  @include breakpoint(xlarge) {
    &.inside {
      max-width: 90%;
    }

    &.wrapped {
      &.limit-width {
        > p,
        > div {
          max-width: 80%;
        }
      }
    }
  }

  @include breakpoint($breakpoint-sublarge) {
    &.horizontal-scroll {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/* stylelint-disable-next-line no-descending-specificity */
section {
  &.background {
    &--y {
      background: $color-yellow;
    }

    &--b {
      background: $color-black;
      color: $color-white;
      position: relative;
      top: -1px;
    }

    &--be {
      background: $color-beige;
    }

    &--w {
      background: $color-white;
    }

    &--p {
      background: $color-pink;
      color: $color-white;
    }

    &--ly {
      background: $color-light-yellow;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .background--404 & {
    position: relative;
    z-index: $zindex-level-2;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
main.main--inverted {
  section:not(.ignore-invert) {
    color: $color-white;
  }
}

figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

/*** GRIDS ***/

.grid {
  &--products {
    > .cell {
      margin-bottom: $spacing-m;
    }
  }
}

.hidden {
  display: none !important;
}

/* Logo */
.pandaLogo {
  display: block;
  position: relative;

  &-content {
    position: relative;
  }

  &-image {
    width: 100%;
    max-width: rem(450);
    position: relative;
  }

  &-text {
    @include sr-only();
  }

  &--stamp {
    display: block;

    .pandaLogo-content {
      max-width: rem(130px);
    }
  }
}

/*** visibility classes ***/
@include breakpoint(small) {
  .hide-for-medium {
    display: block !important;
  }
}

@include breakpoint(medium down) {
  .hide-for-large {
    display: block !important;
  }

  .show-for-large {
    display: none !important;
    visibility: hidden;
    height: 0;
  }
}

@include breakpoint(medium) {
  .hide-for-medium {
    display: none !important;
    visibility: hidden;
    height: 0;
  }
}

@include breakpoint(large) {
  .hide-for-large {
    display: none !important;
    visibility: hidden;
    height: 0;
  }

  .show-for-large {
    display: block !important;
  }
}

/* purgecss end ignore */
