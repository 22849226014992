@import "../config";

$control-size: rem-calc(50);

.pandaAudio {
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  audio {
    margin: auto;
  }

  &__caption {
    text-align: center;
  }

  @include breakpoint(small only) {
    max-width: 100% !important;
  }
}

/* Custom plyr styles */

/* purgecss start ignore */

$plyr-font-size-small: 13px;
$plyr-color: '#4f5b5f';

.plyr {
  $control-size: rem-calc(50);

  &--audio {
    transition: opacity 1s;
    transition-delay: 1s;

    .plyr__control {
      &.plyr__tab-focus {
        background: transparent;
        box-shadow: 0 0 5px 0 rgba($color-text, .1);
      }
    }

    .plyr__controls {
      position: relative;
      border: none;
      background: none;
      padding-left: 0;
      padding-right: 0;

      > button {
        position: absolute;
        left: calc(50% - #{$control-size / 2});
        top: $control-size * -1;
        border: 1px solid rgba($color-black, .3);
        border-radius: 100%;
        height: $control-size;
        width: $control-size;
        transition: border-color .3s;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          border-color: rgba($color-black, .5);
          background-color: transparent;
        }

        svg {
          margin: auto;
          fill: $color-text;
          transform: scale(.7);
        }
      }

      .plyr__playback {
        font-size: rem-calc($plyr-font-size-small);
        color: $color-black;
        text-align: left;
        width: rem-calc(40);
        white-space: nowrap;
      }

      .plyr__playback,
      .plyr__time--duration {
        position: relative;
        top: 1px;
      }

      .plyr__progress {
        height: 5px;
        background: rgba($color-black, .2);
        overflow: hidden;
        margin: 0;
        left: 0;

        input[type='range'] {
          z-index: 10;
          height: 5px;
          margin: 0;
          width: 100%;
          color: $color-text;

          &::-webkit-slider-thumb {
            opacity: 0;
          }

          &::-moz-range-thumb {
            opacity: 0;
          }

          &::-ms-thumb {
            opacity: 0;
          }

          &::-webkit-slider-runnable-track {
            border-radius: 0;
          }

          &::-moz-range-track {
            border-radius: 0;
          }

          &::-moz-range-progress {
            border-radius: 0;
          }

          &::-ms-track {
            border-radius: 0;
          }
        }
      }

      .plyr__progress__buffer {
        width: 100%;
        color: rgba($color-black, .2);
      }

      .plyr__progress__container {
        padding: 0;
        margin: 0;
      }

      .plyr__time {
        margin-left: 0;

        &--duration,
        &--current {
          position: absolute;
          top: 25px;
        }

        &--current {
          left: 0;

          &::before {
            display: none;
          }
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

.pandaAudio--inverted {
  .pandaAudio__caption {
    color: $color-white;
  }

  .plyr--audio {
    .plyr__control {
      &.plyr__tab-focus {
        box-shadow: 0 0 5px 0 rgba($color-white, .1);
      }
    }

    .plyr__controls {
      > button {
        border-color: rgba($color-white, .3);

        &:hover {
          border-color: rgba($color-white, .5);
        }

        svg {
          fill: $color-white;
        }
      }

      .plyr__progress {
        background: rgba($color-white, .1);

        input[type='range'] {
          color: $color-white;
        }
      }

      .plyr__progress__buffer {
        color: rgba($color-white, .1);
      }
    }

    .plyr__time {
      color: $color-white;
    }
  }
}

/* purgecss end ignore */
