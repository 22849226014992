@import "../config";

.pandaSocial {
  margin: 0;
  list-style-type: none;

  li {
    display: inline-block;
    padding: 0 $spacing-xs;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.3;
    }
  }
}
