@import "../config";

.pandaFooter {
  background-color: $color-steel;
  font-size: $font-size-m;

  div {
    color: $color-text;
  }

  &__connect {
    display: flex;

    .pandaFooter__up .pandaBtn {
      display: inline-block;

      .pandaArrow__wrapper {
        margin: 0;
      }
    }
  }

  &__contact {
    background: $color-westas;
    padding: $spacing-l;
    text-align: center;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;

    a {
      color: $color-text;
      text-decoration: underline;
    }
  }

  &__wrapper {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
  }

  &__connect,
  &__linklist,
  &__bottom {
    max-width: $content-wrapped-width;
    margin: 0 auto;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $spacing-m;
    font-size: $font-size-xs;

    &__text {
      a:hover {
        color: $color-text;
      }

      span {
        &::after {
          content: '•';
          display: inline-block;
          margin: 0 $spacing-xs;
        }

        &:last-of-type {
          &::after {
            content: '';
          }
        }
      }
    }
  }

  &__linklist {
    list-style-type: none;
    font-size: $font-size-m;

    > li {
      &::before {
        content: '';
        display: block;
        margin-bottom: $spacing-m;
        width: 100%;
        height: 2px;
        background: $color-text;
        opacity: .06;
      }

      h3 {
        display: inline-block;
        font-family: $font-primary;
        margin: 0;
        font-size: $font-size-m;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      > span {
        font-weight: $font-weight-medium;
      }

      > ul {
        list-style-type: none;
        margin: 0;

        /* stylelint-disable-next-line no-descending-specificity */
        > li > a {
          font-weight: $font-weight-normal;
          color: $color-text;
          margin-bottom: rem-calc(3);
        }
      }
    }
  }

  @include breakpoint(small only) {
    padding: 0 $spacing-m;

    &__connect {
      flex-wrap: wrap;
      justify-content: space-between;

      .pandaLogo {
        flex-basis: 70%;
        order: 1;
      }

      .pandaFooter__up {
        order: 2;
        flex-basis: 30%;
        text-align: right;
      }

      &__text {
        order: 3;
        flex-basis: 100%;
        padding-top: $spacing-m;
      }
    }

    &__linklist {
      > li {
        padding-bottom: $spacing-m;

        > span {
          display: block;
          margin-bottom: $spacing-xs;
        }

        ul > li {
          br {
            display: none;
          }
        }
      }

      &__split {
        display: flex;
        flex-wrap: wrap;

        /* stylelint-disable-next-line no-descending-specificity */
        > li {
          flex-basis: 50%;
        }
      }
    }

    &__bottom {
      flex-wrap: wrap;

      > div {
        flex-basis: 100%;
      }

      &__text {
        order: 2;
        margin-top: $spacing-xl;

        &::before {
          content: '';
          display: block;
          margin-bottom: $spacing-m;
          width: 100%;
          height: 2px;
          background: $color-text;
          opacity: .06;
        }
      }

      &__social {
        order: 1;
        margin-left: -10px;
      }
    }
  }

  @include breakpoint(medium) {
    padding: $spacing-m $spacing-xl;

    &__connect {
      display: flex;
      justify-content: space-between;
      padding-bottom: $spacing-m;

      .pandaLogo {
        flex-basis: calc(25% - 20px);
      }

      &__text {
        flex-basis: auto;
        flex-basis: calc(60% - 10px);
      }

      .pandaFooter__up {
        flex-basis: calc(15% - 20px);
        text-align: right;
      }
    }

    &__linklist {
      display: flex;
      justify-content: space-between;

      > li {
        width: calc(25% - 20px);

        > span {
          display: block;
          margin-bottom: $spacing-s;
        }
      }
    }

    &__bottom {
      padding-top: $spacing-xl;
    }
  }

  @include breakpoint(medium only) {
    &__bottom {
      flex-wrap: wrap;

      &__text,
      &__social {
        flex-basis: 100%;
        text-align: center;
        padding: $spacing-xs 0;
      }

      &__text {
        order: 2;
      }

      &__social {
        order: 1;
      }
    }
  }
}
