@import "../config";
@import "../common/mixins";

/* Arrow styles based on https://codepen.io/andgatjens/pen/PmLqaN */

$arrowSize: 10px;
$arrowBorderSize: 2px;
$arrowBarWidth: 2px;
$arrowBarHeight: 36px;
$arrowGrowing: 14px;
$arrowBarHeightHover: $arrowBarHeight + $arrowGrowing;
$arrowGrowingShift: $arrowGrowing / 2;
$transitionDuration: .2s;
$transitionEasing: ease-out;

@mixin rotate($angle) {
  transform: rotate($angle);
}

@mixin arrow($arrowSize, $arrowBorderSize, $arrowBarWidth, $arrowBarHeight, $arrowGrowing) {
  display: inline-block;
  height: $arrowBarHeight;
  width: $arrowBarHeight;
  position: relative;

  &::after {
    border-bottom-style: solid;
    border-bottom-width: $arrowBorderSize;
    border-right-style: solid;
    border-right-width: $arrowBorderSize;
    content: '';
    display: inline-block;
    height: $arrowSize;
    width: $arrowSize;
    left: calc(50% - #{$arrowSize/2});
    position: absolute;
    bottom: 0;
    transition: bottom $transitionDuration $transitionEasing;
    @include rotate(45deg);
  }

  &::before {
    bottom: 0;
    content: '';
    height: $arrowBarHeight;
    width: $arrowBarWidth;
    position: absolute;
    left: calc(50% - #{$arrowBorderSize/2});
    transform-origin: center center;
    transition: transform $transitionDuration $transitionEasing, height $transitionDuration $transitionEasing;
  }

  /* States */
  &--top {
    @include rotate(180deg);
  }

  &--right {
    @include rotate(270deg);
  }

  &--left {
    @include rotate(90deg);
  }
}

@mixin arrowColor($color) {
  &,
  .pandaArrow__wrapper {
    &::after {
      border-color: $color;
    }

    &::before {
      background-color: $color;
    }
  }
}

/* purgecss start ignore */
.pandaArrow {
  &__wrapper {
    display: inline-block;
    width: $arrowBarHeight;
    height: $arrowBarHeight;
    margin: $spacing-l;
    @include arrow($arrowSize, $arrowBorderSize, $arrowBarWidth, $arrowBarHeight, $arrowGrowing);
    @include arrowColor($color-text);
  }

  &--with-text {
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;

    .pandaArrow {
      &__wrapper {
        margin: $spacing-xs $spacing-m $spacing-xs rem-calc(5);
      }

      &__text {
        position: relative;
        transition: margin $transitionDuration $transitionEasing;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: $color-text;
          bottom: -2px;
          left: 0;
          right: auto;
          transition: opacity 0.3s;
          display: none;
        }
      }
    }
  }

  &--inverted {
    .pandaArrow {
      &__wrapper {
        @include arrowColor($color-white);
      }

      &__text {
        color: $color-white;

        &::after {
          background: $color-white;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;

    .pandaArrow {
      &__wrapper {
        &::before {
          height: $arrowBarHeightHover;
          transform: translateY($arrowGrowingShift);
        }

        &::after {
          bottom: $arrowGrowingShift*-1;
        }
      }

      &__text {
        margin-left: $arrowGrowingShift;
      }
    }
  }
}

.pandaBtn {
  display: inline-block;

  &:hover {
    color: $color-text;
  }
}

/* purgecss end ignore */
