@import "../config";
@import "../common/mixins";

.pandaNavFlyout {
  max-height: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  z-index: $zindex-level-1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: transform .3s linear .1s, background-color .3s linear .6s;
  transform: translateY(-100%);
  color: $color-text;

  &::after {
    background: url(../../img/dropshadow_stickynav.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    content: '';
    bottom: -3px;
    height: 3px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
  }

  h1 {
    display: none;
  }

  &__menu {
    @include fade-in($order: 1);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 1.8;

      li {
        font-family: $font-decor;
        font-size: 36px;

        &.is-active {
          opacity: .3;
        }
      }
    }
  }

  &__contact {
    @include fade-in($order: 1.5);

    font-size: $font-size-s;
    display: flex;
    flex-wrap: wrap;

    &__item-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: 100%;

      > span {
        flex-basis: 100%;
        display: block;
        margin-bottom: $spacing-s;
      }
    }

    &__item {
      flex-basis: 50%;
    }

    &__social,
    &__divider,
    > span {
      flex-basis: 100%;
    }

    &__social {
      margin-bottom: $spacing-xxs;
    }

    &__divider {
      height: 1px;
      width: 100%;
      background: $color-border;
    }
  }

  &__menu,
  &__contact {
    span {
      display: block;
      color: $color-text-faded;
      font-size: $font-size-s;
    }
  }

  @include breakpoint(medium) {
    border: 20px solid $color-white;
    border-bottom: 0;
    overflow: visible;

    h1 {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity .3s;

      .pandaHeader__title {
        display: none;
      }
    }

    .pandaNavFlyout {
      &__wrapper {
        padding-top: rem(130px);
        padding-bottom: rem(130px);
        display: flex;
      }

      &__menu,
      &__contact {
        flex-basis: 50%;
      }

      &__contact {
        &__divider {
          align-self: center;
        }

        &__social {
          align-self: flex-end;
        }
      }
    }
  }

  @include breakpoint(small only) {
    height: 100%;

    .wrapped {
      height: 100%;
    }

    .pandaNavFlyout {
      &__wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
      }

      &__contact {
        margin-top: $spacing-xxl;

        &__divider {
          margin: $spacing-xxl 0 $spacing-m;
        }
      }
    }
  }

  @include breakpoint(medium only) {
    .pandaNavFlyout {
      &__wrapper {
        &.wrapped.inside {
          max-width: 100%;
        }
      }
    }
  }

  &.is-active {
    background: $color-white;
    transform: translateY(0);
    transition: transform .3s linear 0s, background-color .1s linear 0s;

    @include breakpoint(medium) {
      &::after {
        opacity: 1;
        visibility: visible;
      }

      h1 {
        opacity: 1;
      }
    }
  }
}
