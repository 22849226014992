@import "../config";

/* purgecss start ignore */
.pandaImageText {
  position: relative;
  overflow: hidden;

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;

    .caption {
      width: 100%;
    }
  }

  &__text {
    padding: $spacing-xl $spacing-m;
    position: relative;

    p {
      a {
        text-decoration: underline;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .pandaImageText--full & {
        text-align: center;
      }
    }

    .pandaImageText--top & {
      padding: 0 0 $spacing-l*2 0;
    }

    .pandaImageText--bottom & {
      padding: $spacing-l*2 0 0  0;
    }
  }

  @include breakpoint(small only) {
    &--overlay {
      .pandaImageText {
        &__image {
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $color-black;
            opacity: 0.8;
          }
        }

        &__text {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  @include breakpoint(medium) {
    &__text {
      padding: $spacing-xl * 2.5 5.25rem;
    }
  }

  @include breakpoint(medium only) {
    &__text {
      .case-detail & {
        padding: $spacing-xl *2  0 0 0;
      }

      .pandaImageText--top & {
        padding: 0 0 $spacing-l*2 0;
      }

      .pandaImageText--bottom & {
        padding: $spacing-l*2 0 0  0;
      }
    }
  }

  @include breakpoint(large) {
    display: flex;

    &__image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__text {
      padding: $spacing-xl;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;

      .pandaBtn {
        align-self: flex-end;
      }

      .fit-in-grid & {
        padding-right: 0;
      }
    }

    /* ratios */
    &--full {
      flex-direction: column;

      .pandaImageText {
        /* stylelint-disable-next-line no-descending-specificity */
        &__image,
        &__text {
          flex-basis: 100%;
        }

        &__image {
          .case-detail & {
            flex-basis: 100%;
          }
        }

        &__text {
          .case-detail & {
            flex-basis: 100%;
          }
        }
      }
    }

    &--half {
      .pandaImageText {

        /* stylelint-disable-next-line no-descending-specificity */
        &__image {
          flex-basis: 50%;

          .case-detail & {
            flex-basis: calc(50% - 20px);
          }
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &__text {
          flex-basis: 50%;

          .case-detail & {
            flex-basis: calc(50% + 20px);
          }
        }
      }
    }

    &--img-third {
      .pandaImageText {

        /* stylelint-disable-next-line no-descending-specificity */
        &__image {
          flex-basis: 40%;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &__text {
          flex-basis: 60%;
        }
      }
    }

    &--top {
      .pandaImageText {

        /* stylelint-disable-next-line no-descending-specificity */
        &__text {
          padding-top: 0;
        }
      }
    }
  }

  &--switch {
    &-small {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .pandaImageText {
        &__image {
          order: 2;
        }

        &__text {
          order: 1;
        }
      }
    }

    &-medium {
      @include breakpoint(medium) {
        .pandaImageText {
          &__image {
            order: 2;
          }

          &__text {
            order: 1;
          }
        }
      }

      .fit-in-grid & {
        .pandaImageText__text {
          padding-left: 0;
          padding-right: $spacing-xl;
        }
      }
    }
  }

  @include breakpoint(xlarge) {
    &__text {
      padding: $spacing-xl*2;
    }
  }
}

.pandaMusic {
  margin-top: $spacing-m;
  padding-bottom: $spacing-m;
  border-bottom: 1px solid $color-border;

  &:last-of-type {
    border: none;
  }

  &__text {
    p a {
      text-decoration: underline;
    }
  }

  &__image {
    margin-bottom: $spacing-m;
  }

  @include breakpoint(medium) {
    display: flex;
    margin-top: $spacing-xl;
    padding-bottom: $spacing-xl;

    &__image {
      width: rem(200);
      margin-bottom: 0;
      flex-shrink: 0;
      margin-right: $spacing-xl;
    }
  }
}

.pandaImageBrowser {
  border-bottom-left-radius: rem(2);
  border-bottom-right-radius: rem(2);

  &__topBar {
    border-top-left-radius: rem(10);
    border-top-right-radius: rem(10);
    background: $color-light-gray;
    height: rem(30);
    width: 100%;
  }

  &__scroll {
    height: 60vh;
    min-height: rem(400);
    max-height: rem(900);
    width: 100%;
    overflow-y: scroll;

    img {
      max-width: 100%;
    }
  }
}

/* purgecss end ignore */
