img {
  position: relative;

  a {
    display: block;
    border: none;
  }

  &.blur-up {
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
  }

  &.blur-up.lazyloaded {
    filter: blur(0);
  }
}

.pandaImage {
  display: block;
}

.imageShadow {
  box-shadow: 0 20px 40px -15px rgba(0, 0, 0, .2);
}
