/**
 * components/typography.css
 *
 * Typography rules.
 *
 */
@import "../config";

@mixin h1 {
  font-family: $font-decor;
  font-size: $font-size-title-xxl;
  line-height: 1.3;
  margin-bottom: $spacing-l;

  @include breakpoint(small only) {
    font-size: $font-size-title-l;
    margin-bottom: $spacing-m;
  }
}

@mixin h2 {
  font-family: $font-decor;
  font-size: $font-size-title-xl;

  @include breakpoint(small only) {
    font-size: $font-size-title-m;
  }
}

@mixin h3 {
  font-family: $font-decor;
  font-size: $font-size-title-l;
  line-height: 1.3;

  @include breakpoint(small only) {
    font-size: $font-size-title-s;
  }
}

@mixin h4 {
  font-family: $font-decor;
  font-size: $font-size-title-m;
  line-height: 1.3;

  @include breakpoint(small only) {
    font-size: $font-size-title-s;
  }
}

p {
  line-height: $line-height-large;

  &.small {
    font-size: $font-size-xs;
  }

  &.tiny {
    font-size: $font-size-xxs;
  }

  &.light {
    color: $color-text-faded;

    .main--inverted & {
      color: $color-white;
      opacity: .6;
    }
  }

  &.wrapped {
    max-width: $paragraph-wrapped;
    margin-left: auto;
    margin-right: auto;
  }

  > small,
  + small {
    font-size: $font-size-xs;
    color: $color-text-faded;
    display: block;
    margin-bottom: $spacing-xs;
    padding-top: $spacing-s;
  }
}

strong {
  font-weight: $font-weight-medium;
}

a {
  color: inherit;
  transition: color 0.3s ease-out;

  &.accent {
    color: $color-accent;
  }

  &.underline {
    text-decoration: underline;
  }
}

h1,
.h1 {
  @include h1();
}

h2,
.h2 {
  @include h2();
}

h3,
.h3 {
  @include h3();
}

h4,
.h4 {
  @include h4();
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  display: block;

  &.text-left {
    text-align: left;
  }

  > a {
    color: $color-text;
  }

  &.inverted {
    color: $color-white;
  }

  > em,
  > strong {
    font-weight: inherit;
    font-style: inherit;
    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }

  .text & {
    margin-bottom: $spacing-m;
  }
}

a:hover {
  color: $color-text-faded;
}

a:focus {
  color: $color-text;
}

blockquote {
  font-family: $font-decor;
  font-style: italic;
  font-size: $font-size-title-m;
  color: $color-text;
  border-left: 0;
  padding: 0;
  margin: $spacing-m 0;

  @include breakpoint(medium) {
    max-width: 80%;
  }

  .main--inverted & {
    color: $color-white;
  }

  .text-center & {
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin category {
  font-family: $font-primary;
  font-size: $font-size-detail-l;
  font-weight: $font-weight-medium;
  color: $color-text-faded;
  text-transform: uppercase;
}

.link-list,
.content p,
.case-detail p {
  line-height: $line-height-large;

  a {
    text-decoration: underline;
  }
}

.pandaSubhead {
  font-size: $font-size-s;
  padding: $spacing-xs $spacing-m;

  .inside & {
    padding: $spacing-xs 0;
  }

  &.light {
    color: $color-text-faded;
  }

  @include breakpoint(medium) {
    padding: $spacing-xs 0;
  }

  + h1,
  + h2,
  + h3 {
    + p {
      margin-top: $spacing-xl;
    }
  }

  .inverted & {
    color: $color-white;
    opacity: 0.7;
  }
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-column {
    @include breakpoint(medium) {
      column-count: 2;
      column-gap: $spacing-xxl;
    }
  }
}

.link-underline,
.pandaFooter__connect__text p > a {
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    right: auto;
    left: 0;
    bottom: 0;
    background: $color-text;
    transition: width 0.3s;
  }

  &:hover {
    color: inherit;

    &::after {
      width: 0;
      left: auto;
      right: 0;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .inverted &,
  .main--inverted section & {
    &::after {
      background: $color-white;
    }
  }
}

.link-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

/* purgecss start ignore */
@include breakpoint(medium) {
  .col {
    &-2 {
      column-count: 2;
      column-gap: $spacing-l;
    }

    &-3 {
      column-count: 3;
      column-gap: $spacing-l;
    }

    &-1,
    &-2,
    &-3 {
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.caption {
  font-size: $font-size-xs;
  text-align: center;
  color: $color-text;
  padding: $spacing-s 0 0 0;

  @include breakpoint(medium) {
    text-align: right;

    .full-width & {
      padding-right: $spacing-s;
    }
  }
}

div {
  .main--inverted &,
  &.inverted {
    color: $color-white;
  }

  &.inverted {
    .main--inverted & {
      color: $color-text;
    }
  }
}

@include breakpoint(large) {
  .pandaCarousel {
    + .caption {
      display: block;
    }

    &--one-item {
      + .caption {
        position: relative;
        top: rem(-32);
      }
    }
  }
}

@include breakpoint(small only) {
  .wrapped.outside .caption {
    padding-left: $spacing-s;
    padding-right: $spacing-s;
  }
}

/* purgecss end ignore */
