@import "../config";

$control-size: rem-calc(50);

.pandaVideo {
  text-align: center;
  width: 100%;

  @include breakpoint(medium) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  video {
    margin: auto;
  }

  @include breakpoint(small only) {
    max-width: 100% !important;
  }
}

/* Custom plyr styles */

/* purgecss start ignore */

$plyr-color: '#4f5b5f';

.plyr {
  &__badge {
    background: $plyr-color;
  }

  &__control {
    svg {
      fill: $color-white;
      width: 18px;
    }

    &--overlaid {
      background: rgba(255, 255, 255, .8);
      box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
      color: $color-text;
      padding: 30px;

      svg {
        fill: $color-text;
      }
    }

    &.plyr__tab-focus {
      box-shadow: 0 0 0 5px rgba(255, 255, 255, .5);
    }
  }

  &--video {
    .plyr__control {
      svg {
        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .15));
      }

      .plyr__tab-focus,
      &:hover,
      &[aria-expanded=true] {
        background: rgba(255, 255, 255, 0);
        color: $color-white;
        opacity: 1;
      }

      &--overlaid {
        &:focus,
        &:hover {
          background: $color-white;
        }
      }
    }

    .plyr__controls {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4));
      color: #fff;
    }

    &.plyr {
      &--hide-controls,
      &--stopped {
        .plyr__controls {
          opacity: 0;
          pointer-events: none;
          transform: translateY(100%);
        }
      }

      &--paused:not(.plyr--stopped) {
        .plyr__control--overlaid {
          opacity: 0;
          pointer-events: none;
          transform: translateY(100%);
        }
      }
    }
  }

  &--full-ui input[type=range] {
    color: $color-white;
  }

  &--full-ui {
    .plyr__video-embed > .plyr__video-embed__container {
      transform: translateY(-38.282%) !important;
    }
  }

  &__video-wrapper {
    background: $color-page;
  }

  &__volume {
    flex-grow: 0;
  }

  &__poster {
    background-color: transparent;
  }

  [type='button'].plyr__control--overlaid {
    border-radius: 100%;
  }
}

/* purgecss end ignore */
