@import "../config";

.pandaMaps {
  width: 100%;
  background-color: $color-light-yellow;

  &__map {
    width: 100%;
    height: rem(400);
  }

  &__text {
    padding: $spacing-m;
  }

  @include breakpoint(medium) {
    display: flex;
    align-items: flex-end;

    &__map {
      flex-basis: 70%;
      height: rem(600);
    }

    &__text {
      padding: $spacing-m $spacing-xl;
      flex-basis: 30%;
    }
  }
}

#pandaMap {
  canvas {
    background: white !important;
  }
}
