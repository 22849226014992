@import "../config";

#pandaIntro {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $color-beige;
  transform: translateY(-120%);
  transition: transform 0.5s ease-in-out;
  z-index: $zindex-level-3;

  .pandaIntro {
    &__code {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__credit {
      position: absolute;
      font-size: $font-size-xs;
      right: $spacing-m;
      bottom: $spacing-m;
      z-index: 10002;

      > a {
        text-decoration: underline;
        text-align: right;
        color: $color-white;
      }

      &.inverted > a {
        color: $color-text;
      }
    }

    &__cta {
      position: absolute;
      bottom: $spacing-m;
      left: 0;
      right: 0;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-xxs;
      z-index: 10002;

      &.inverted {
        color: $color-text;
        opacity: 0.7;
      }
    }
  }

  &.is-active {
    transform: translateY(0);
    transition: none;
    z-index: 10001;

    &:hover {
      cursor: pointer;
    }
  }
}
