@import "../config";

.pandaKeyvisual {
  position: relative;
  width: 100%;

  &__contact,
  &__icon {
    display: none;
  }

  &__caption {
    text-align: right;
    font-size: $font-size-xxs;
    padding: $spacing-m;

    * {
      text-align: right;
    }

    &--large {
      display: none;
    }
  }

  @include breakpoint(medium) {
    padding-bottom: $spacing-xl;

    // Overlapping colors on bottom
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      height: 60%;
      width: 100%;
      z-index: 0;
    }

    &.overlap {
      &--y::before {
        background: $color-yellow;
      }

      &--b::before {
        background: $color-black;
        color: $color-white;
      }

      &--be::before {
        background: $color-beige;
      }

      &--w::before {
        background: $color-white;
      }

      &--p::before {
        background: $color-pink;
        color: $color-white;
      }

      &--ly::before {
        background: $color-light-yellow;
      }
    }

    + section {
      padding-bottom: $spacing-xl * 2;
    }

    .wrapped.outside {
      padding-left: $spacing-m;
      padding-right: $spacing-m;
      width: $el-width-menu-safe;
    }

    &__caption {
      position: relative;
      padding: $spacing-xs 0 $spacing-xs;
      max-width: 80%;
      margin-left: auto;
      margin-right: 0;

      &--large {
        display: block;
      }

      &--small {
        display: none;
      }
    }

    &__icon {
      display: block;
      position: absolute;
      right: $spacing-m;
      bottom: 34%;
      margin-bottom: $spacing-m;
      color: $color-beige;

      .pandaIcon {
        width: 60px;
        height: 60px;
      }

      svg {
        fill: $color-beige !important;
      }
    }
  }

  @include breakpoint(large) {
    &::before {
      height: 54%;
    }

    .wrapped.outside {
      width: $el-width-menu-safe;
      padding-left: $spacing-xl;
      padding-right: $spacing-xl;
    }
  }

  @include breakpoint(xlarge) {
    &::before {
      height: 34%;
    }

    &__contact {
      font-size: $font-size-xs;
      color: $color-text;
      display: flex;
      position: absolute;
      left: $spacing-m;
      top: $spacing-xl;
      transform: rotate(-90deg) translateX(-100%);
      transform-origin: 0 0;

      p {
        display: block;
        margin-right: $spacing-m;
      }
    }
  }

  @include breakpoint(small only) {
    &.overlap {
      &--y .pandaKeyvisual__caption {
        background: $color-yellow;
      }

      &--b .pandaKeyvisual__caption {
        background: $color-black;
        color: $color-white;
      }

      &--b .pandaKeyvisual__image {
        background: $color-black;
      }

      &--be .pandaKeyvisual__caption {
        background: $color-beige;
      }

      &--w .pandaKeyvisual__caption {
        background: $color-white;
      }

      &--p .pandaKeyvisual__caption {
        background: $color-pink;
        color: $color-white;
      }

      &--ly .pandaKeyvisual__caption {
        background: $color-light-yellow;
      }
    }
  }

  &--about {
    .pandaKeyvisual {
      &__text {
        position: relative;
        padding: $spacing-s 0;
        color: $color-white;
        font-size: $font-size-s;
      }

      @include breakpoint(small only) {
        margin-bottom: 0;

        &__text {
          padding: $spacing-s $spacing-m;
          background: $color-black;
        }
      }
    }
  }
}
