@import "../config";

$loading-slash-size: 60px !default;
$loading-slash-layer-width: 30px !default;
$loading-slash-layer-height: 3px !default;
$loading-slash-transformed-width: 12px !default;
$loading-slash-transformed-height: 3px !default;
$loading-slash-layer-color: $color-white !default;
$loading-slash-active-layer-color: $loading-slash-layer-color !default;
$loading-slash-timing-function: cubic-bezier(0.33333, 0.66667, 0.66667, 1);

/*! purgecss start ignore */
.loading-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba($color-beige, .4);
  transition: visibility .3s, opacity .3s, background-color .3s;
  will-change: visibility, opacity, background-color;
  z-index: 100000;

  &.loading-layer--active {
    visibility: visible;
    opacity: 1;
  }

  &.loading-layer--opaque {
    visibility: visible;
    opacity: 1;
    background-color: rgba($color-beige, 1);
  }

  .loading-layer-anim {
    width: $loading-slash-size;
    height: $loading-slash-size;
    display: inline-block;
    position: absolute;
    left: calc(50% - 45px);
    top: calc(50% - 45px);

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $color-text;
    }

    &__box {
      transform: rotate(90deg);
      width: $loading-slash-size;
      height: $loading-slash-size;
      display: inline-block;
      position: relative;
      z-index: 100001;
    }

    &__inner {
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      animation: boxRotate 1s ease-in-out infinite;

      &::before {
        width: $loading-slash-layer-width;
        height: $loading-slash-layer-height;
        top: $loading-slash-size / 2;
        left: ($loading-slash-size / 2) - ($loading-slash-layer-width / 2);
        background-color: $loading-slash-layer-color;
        border-radius: 0;
        position: absolute;
        content: "";
        display: block;
        transform: rotate(45deg) translateY(-2px);
      }
    }
  }

  @keyframes boxRotate {
    100% {
      transform: rotate(360deg);
    }
  }
}

/*! purgecss end ignore */
