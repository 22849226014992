/**
 * The entry point for the css.
 *
 */

/**
 * Import the vendor.scss styles
 */
@import url('vendor.scss');

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */
@import 'common/mixins';
@import 'common/global';
@import 'common/typography';
@import 'common/webfonts';
@import 'config';

@import 'common/animation';
@import 'common/button';
@import 'common/element-scale';
@import 'common/forms';
@import 'common/icon';
@import 'common/images';
@import 'common/spacing';
@import 'common/table';
@import 'common/tooltip';

/* Import component classes */
@import 'components/carousel';
@import 'components/cookies';
@import 'components/customer-list';
@import 'components/footer';
@import 'components/hamburger';
@import 'components/header';
@import 'components/image-text';
@import 'components/image-placeholder';
@import 'components/intro';
@import 'components/keyvisual';
@import 'components/logo-animation';
@import 'components/maps';
@import 'components/nav-flyout';
@import 'components/project';
@import 'components/social';
@import 'components/video';
@import 'components/loading-layer';
@import 'components/audio';

/* import page transitions */
@import 'common/page-transition';
