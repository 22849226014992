@import "../config";
@import "../common/mixins";

$logo-height-large: 90px;

.pandaHeader {
  position: relative;
  z-index: 10;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__logo {
    display: none;

    &--small {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }

  h1 {
    font-size: $font-size-detail-s;
    font-family: $font-primary;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 0 auto 0 0;
    position: relative;
  }

  .pandaLogoAnim {
    display: none;
  }

  &__name {
    display: none;
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    margin-left: $spacing-s;
  }

  &__menu {
    display: none;
  }

  &__menu-toggle {
    position: fixed;
    right: $spacing-xs;
    top: $spacing-xs;
    z-index: $zindex-level-2;
    width: 60px;
    height: 60px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: $color-white;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transition: background-color .3s ease-out;
    }

    &:hover {
      &::before {
        animation: hoverscale .3s ease-out;
      }
    }
  }

  &.is-open {
    .pandaHeader {
      &__menu-toggle {
        &::before {
          background-color: $color-white;
        }
      }
    }
  }

  @include breakpoint(medium) {
    height: $logo-height-large;

    &__wrapper {
      height: 100%;
    }

    .pandaLogoAnim {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: $logo-height-large;
      width: $logo-height-large;
      z-index: $zindex-level-3;
    }

    &__logo {
      &--small {
        display: none;
      }

      &--large {
        display: block;
        z-index: $zindex-level-2;
        position: absolute;
        top: 0;
        height: $logo-height-large;
        width: $logo-height-large;
        transition: opacity .4s, transform .1s .4s;
      }
    }

    h1 {
      font-size: $font-size-detail-m;
      margin-bottom: 0;
      height: $logo-height-large;
      display: flex;
      align-items: center;
    }

    .pandaLogo-content {
      &--default {
        display: block;
      }

      &--inverted {
        display: none;
      }
    }

    &__title {
      margin-left: rem(110);
    }

    &__menu-toggle {
      transition: transform .3s ease-out;
      transform: scale(0);
      transform-origin: center center;
      right: $spacing-xl;
      top: $spacing-xl;
    }

    &__menu {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      font-size: $font-size-xs;
      margin: 0;

      li {
        padding: rem(5);
        margin: 0 $spacing-l;
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          width: 0%;
          height: 1px;
          left: auto;
          right: 0;
          bottom: 0;
          background: $color-text;
          transition: width 0.3s;
        }

        &:hover {
          a {
            color: $color-text;
          }

          &::after {
            width: 100%;
            left: 0;
            right: auto;
          }
        }

        &.is-active {
          opacity: .3;

          &::after {
            width: 100%;
          }
        }
      }
    }

    &.out-of-view {
      .pandaHeader__menu-toggle {
        transform: scale(1);
      }
    }

    &.is-open {
      height: auto;

      .pandaHeader__menu-toggle {
        transform: scale(1);
      }

      .pandaLogo {
        .pandaLogo-content {
          &--default {
            display: block;
          }

          &--inverted {
            display: none;
          }
        }
      }
    }
  }

  @include breakpoint(small only) {
    background: $color-white !important;
    padding: $spacing-m;
  }

  &--inverted:not(.is-open) {
    @include breakpoint(medium) {
      color: $color-white;
    }

    .pandaLogo-content {
      &--default {
        display: none;
      }

      &--inverted {
        display: block;
      }
    }

    .pandaLogoAnim-inner,
    .pandaLogoAnim:hover .pandaLogoAnim-inner {
      &::before,
      &::after {
        background-color: $color-text !important;
      }
    }

    .pandaHeader__menu {
      li {
        &::after {
          background: $color-white;
        }

        &:hover {
          a {
            color: $color-white;
          }
        }
      }
    }
  }
}

@keyframes underline-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes underline-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}
