@import "../config";

$_breakpoint-carousel: 1330px;
$_max-width: rem-calc(1400);

@mixin colorDots($_color) {
  .swiper-pagination-bullet {
    background: $_color;
    border-color: $_color;

    &:hover {
      background: $_color;
    }

    &.swiper-pagination-bullet-active {
      background: $_color;
    }
  }
}

/* purgecss start ignore */
.pandaCarousel {
  $self: &;

  position: relative;
  max-width: $content-width-outside;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-overflow-scrolling: touch;

  .swiper-container {
    max-width: $content-width - $spacing-xl;
    width: 100%;
  }

  .swiper-wrapper {
    .ie9 & {
      overflow: hidden;
    }
  }

  .swiper-pagination {
    position: relative;
    margin-top: $spacing-m;
    text-align: center;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: rem(10);
    height: rem(10);
    margin: 0 3px !important;
    background: $color-text-bg;
    border-color: $color-text-bg;
    border-radius: 100%;
    opacity: 0.4;
    transition: all 0.3s;

    #{ $self }--one-item &,
    #{ $self }--two-items &,
    #{ $self }--three-items & {
      background: $color-text-bg;
      opacity: 0.4;
    }

    &:hover {
      background: $color-text;
      opacity: .6;
      cursor: pointer;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-dark-gray;
      }
    }

    &:focus {
      outline: none;
    }

    &.swiper-pagination-bullet-active {
      background: $color-text-bg;
      opacity: 1;
      border: none;

      #{ $self }--one-item &,
      #{ $self }--two-items &,
      #{ $self }--three-items & {
        background: $color-text-bg;
        opacity: 1;
      }

      &:hover {
        #{ $self }--one-item &,
        #{ $self }--two-items &,
        #{ $self }--three-items & {
          background: $color-dark-gray;
        }
      }
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    left: $spacing-m;
  }

  .swiper-button-next {
    right: $spacing-m;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: rem(30);
    height: rem(30);
    top: 50%;
    margin-top: rem(-15);
    opacity: .7;
    z-index: $zindex-level-3;
    transition: opacity .3s;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url('../../img/chevron.svg');
    display: none;
  }

  &--one-item {
    .swiper-button-prev,
    .swiper-button-next {
      background-image: url('../../img/chevron-white.svg');
    }
  }

  &--one-item,
  &--two-items,
  &--three-items {
    .swiper-container {
      max-width: $_max-width;
    }

    .swiper-slide {
      .pandaImage {
        max-width: 100%;
        width: 100%;
      }

      @include breakpoint(small only) {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  &--spacing {
    width: calc(100% - 40px);
  }

  @include breakpoint(medium) {
    &--one-item {
      .swiper-button-prev,
      .swiper-button-next {
        &:not(.swiper-button-lock) {
          display: block;
        }
      }
    }

    &--three-items {
      .swiper-slide {
        max-width: 33.33%;
      }

      .swiper-pagination {
        display: none;
      }
    }

    &--two-items {
      .swiper-slide {
        max-width: 50%;
      }

      .swiper-pagination {
        display: none;
      }
    }

    .caption-pagination {
      position: relative;

      > .caption {
        position: absolute;
        max-width: calc(50% - 60px);
        right: 0;
        padding-top: rem(15);
      }

      > .swiper-pagination {
        margin-top: 0;
        padding-top: rem(10);
      }
    }

    &--spacing {
      width: 100%;

      .swiper-wrapper {
        justify-content: space-between;
      }

      &.pandaCarousel {
        &--two-items {
          .swiper-slide {
            width: calc(50% - 20px);
          }
        }

        &--three-items {
          .swiper-slide {
            width: calc(33.33% - 20px);
          }
        }
      }
    }
  }

  &--invertDots {
    @include colorDots($color-white);
  }

  &--invertArrows {
    .swiper-button-prev,
    .swiper-button-next {
      background-image: url('../../img/chevron.svg');
    }
  }

  main.main--inverted & {
    &:not(.pandaCarousel--invertDots) {
      @include colorDots($color-white);
    }

    &.pandaCarousel--invertDots {
      @include colorDots($color-text-bg);
    }
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.noCarousel {
  max-width: $_max-width;
  width: 100%;
  margin: auto;
  text-align: center;

  > .scale-container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* purgecss end ignore */
