@import "../config";

/* purgecss start ignore */

/* Active state, define the transition,
here, same for leave and enter */
.fade-leave-active {
  transition: none;
}

.fade-enter-active,
.slide-leave-active,
.slide-enter-active {
  transition: opacity 450ms ease, transform 650ms ease-in-out;
}

/* Fade states */
.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

/* Slide states */
.slide-leave,
.slide-enter-to {
  transform: translateX(0);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

[data-aos="info"] {
  opacity: 0;
  transition-property: opacity;

  &.aos-animate {
    opacity: 1;
  }
}

/* purgecss end ignore */
