@import "../config";

@charset "UTF-8";

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

/* Settings
// ================================================== */
$slash-size: 90px !default;
$slash-layer-width: 40px !default;
$slash-layer-height: 3px !default;
$slash-transformed-width: 12px !default;
$slash-transformed-height: 3px !default;
$slash-layer-color: $color-white !default;
$slash-active-layer-color: $slash-layer-color !default;

.pandaLogoAnim {
  width: $slash-size;
  height: $slash-size;
  display: inline-block;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  /* Normalize (<button>) */
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: hidden;

  &-box {
    transform: skewX(-18deg) rotate(-90deg);
    width: $slash-size;
    height: $slash-size;
    display: inline-block;
    position: relative;
    left: -3px;
  }

  &-inner {
    display: block;

    &::before,
    &::after {
      width: $slash-layer-width;
      height: $slash-layer-height;
      background-color: $slash-layer-color;
      border-radius: 0;
      position: absolute;
      content: "";
      display: block;
      left: ($slash-size / 2) - ($slash-layer-width / 2);
      top: ($slash-size / 2) - ($slash-layer-height / 2);
      transition: all 0.15s cubic-bezier(0.33333, 0.66667, 0.66667, 1);
    }

    &::before {
      top: $slash-size / 2;
    }

    &::after {
      top: $slash-size / 2;
      opacity: 0;
    }
  }

  @include hover-supported() {
    &:hover {
      .pandaLogoAnim {
        &-box {
          transform: rotate(-45deg) translateX(-1px) translateY(1px);
        }

        &-inner {
          background-color: transparent !important;

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
            opacity: 1;
          }

          &::after,
          &::before {
            background-color: $slash-active-layer-color;
            width: $slash-transformed-width;
            height: $slash-transformed-height;
            top: $slash-size / 2 - ($slash-transformed-height / 2);
            left: $slash-size / 2 - ($slash-transformed-width / 4);
          }
        }
      }
    }
  }
}
